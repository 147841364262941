






























































import { Component } from 'vue-property-decorator';
import UnitsToolbar from './UnitsToolbar.vue';

@Component
export default class UnitsBottomToolbar extends UnitsToolbar {
}
